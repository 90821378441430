.full-h {
  height: 100% !important;
}

.full-w {
  width: 100% !important;
}

.full-wh {
  height: 100%;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.text {
  font-size: 22px !important;
}

.mat-input-element {
  color: var(--color-black) !important;
}

.text-white {
  color: var(--color-white);
}

.remove-padding-form-field {
  mat-form-field {
    .mat-form-field-wrapper {
      padding: 0 !important;
    }
  }
}

.mat-danger-button {
  color: var(--color-white) !important;
  background-color: var(--color-danger) !important;
}

.mat-primary-button {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.mat-primary-button:hover {
  background-color: var(--color-primary-300) !important;
}


.mat-accent-button {
  background-color: var(--color-accent) !important;
  color: var(--color-white) !important;
}

.mat-accent-button:hover {
  background-color: var(--color-accent-300) !important;
}

.mat-white-button {
  background-color: var(--color-white) !important;
  color: var(--color-primary-600) !important;
}

.mat-white-button:hover {
  background-color: var(--color-white-0_6-transparent) !important;
}


.mat-light-blue-300-button {
  color: var(--color-white) !important;
  background-color: var(--color-light-blue-300) !important;
}

.mat-light-blue-300-button:hover {
  background-color: var(--color-light-blue-400) !important;
}

.only-desktop {
  display: unset;
}

.error-form {
  color: var(--color-error) !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
}

.disable {
  pointer-events: none;
}

.only-mobile {
  display: none !important;
}

.text-center {
  text-align: center;
}

.text-middle {
  vertical-align: middle;
}

.icon-l {
  .material-icons {
    font-size: 55px !important;
  }

  .mat-icon {
    height: 55px !important;
    width: 55px !important;
  }
}

.custom-mat-form-field-bg-white {
  mat-form-field .mat-form-field-outline-start {
    background-color: white !important;
  }

  mat-form-field .mat-form-field-outline-gap {
    background-color: white !important;
  }

  mat-form-field .mat-form-field-outline-end {
    background-color: white !important;
  }

}

.disable-icon {
  .mat-icon {
    color: var(--color-gray-blue-170) !important;
  }

  pointer-events: none !important;
  cursor: default !important;
}


.one-line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: normal;
}

.two-line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: normal;
}

.icon-xs {
  font-size: 18px !important;
  height: 18px !important;
  width: 18px !important;
  min-width: 18px !important;
  color: var(--color-white) !important;
}

.icon-xxs {
  font-size: 14px !important;
  height: 14px !important;
  width: 14px !important;
  min-width: 14px !important;
  color: var(--color-accent) !important;
}

.inner-html {
  a {
    color: var(--color-primary-600);
  }
}

.icon-xs-black {
  font-size: 18px !important;
  height: 18px !important;
  width: 18px !important;
  min-width: 18px !important;
  color: var(--color-black) !important;
}


.icon-banner {
  color: var(--color-black);
  text-decoration: none;
  opacity: .75;

  .material-icons {
    font-size: 15px !important;
  }

  .mat-icon {
    height: 15px !important;
    width: 15px !important;
  }

}

.remove-size-button {
  .mat-icon-button {
    height: 0 !important;
    width: 0 !important;
  }

}

.w-50 {
  width: 50% !important;
}

.mat-custom-dialog {
  .mat-dialog-container {
    padding: 0;
    overflow: unset;
    border-radius: .5rem !important;
  }
}

.mat-custom-dialog-contenuti{
  .mat-dialog-content{
    margin: 0 !important;
    padding: 0 !important;
  }
}

.disable {
  pointer-events: none !important;
  cursor: default !important;
}

.f-bold {
  font-weight: bold !important;
}

h2 {
  font-size: calc(1.020rem + .9vw) !important;
  color: var(--color-accent);
  font-weight: bold;
}

h1 {
  font-size: calc(1.020rem + .9vw) !important;
  color: var(--color-accent);
  font-weight: bold;
}

.custom-mat-menu {
  .mat-menu-item {
    font-size: 18px;
    color: var(--color-accent) !important;
  }
}

mat-expansion-panel-header {
  height: 100% !important;
}


.calendar-custom {
  .cal-cell {
    text-transform: capitalize !important;
    color: var(--color-accent);
  }

  .cal-day-number {
    color: var(--color-accent) !important;
    opacity: unset !important;
  }

  .cal-day-badge {
    min-width: 10px;
    padding: 3px 5px;
    font-size: 9px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
    margin-left: 0;
    margin-top: 0;
  }

  .cal-events {
    max-height: 20px;
  }

  .cal-open-day-events {
    background-color: var(--color-bg-calendar);
    border-radius: 5px;
    text-align: start;
    color: var(--color-accent);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
  }

  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: var(--color-bg-calendar);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .cal-event {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
  }

  .cal-event-sm {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
  }

  .cal-event-title:hover {
    text-decoration: none;
    cursor: default;
  }

  .cal-event-title-cursor-pointer {
    text-decoration: underline !important;
    cursor: pointer;
  }
  .cal-event-title-cursor-pointer:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }


}


@media all and (max-device-width: 1280px) {
  .text {
    font-size: 20px !important;
  }
}


@media all and (max-device-width: 1000px) {
  .icon-banner {
    .material-icons {
      font-size: 15px !important;
    }

    .mat-icon {
      height: 15px !important;
      width: 15px !important;
    }

  }
}


@media all and (max-device-width: 800px) {
  .text {
    font-size: 14px !important;
  }

  .icon-banner {
    .material-icons {
      font-size: 12px !important;
    }

    .mat-icon {
      height: 12px !important;
      width: 12px !important;
    }

  }

  .calendar-custom {

    .cal-event-sm {
      display: none;
    }

    .cal-month-view .cal-header .cal-cell {
      font-size: 9px;
    }
  }
}


// consideriamo mobile da 600 in meno
@media all and (max-device-width: 600px) {
  .text {
    font-size: 12px !important;
  }
  .only-desktop {
    display: none !important;
  }

  .only-mobile {
    display: unset !important;
  }
}


@media all and (max-device-width: 320px) {
  .text {
    font-size: 12px !important;
  }
}

[aria-label = "deny cookies"] {
  display: none !important;
}

.pdfdoc {
  background-image: url('../assets/images/icone/pdf_icon.png');
  background-repeat: no-repeat;
  padding-left: 70px;
  display: block;
  height: 65px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.wdoc {
  background-image: url('../assets/images/icone/word-doc-icon.png');
  background-repeat: no-repeat;
  padding-left: 70px;
  display: block;
  height: 65px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-accent-400);
}

::-webkit-scrollbar {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-accent-400);
  border-radius: 10px;
  height: 1px;
}

.mat-expansion-sessioni-custom{

  .mat-expansion-panel-body{
    padding: 0 !important;
  }

  .mat-expansion-panel-header.mat-expanded {
    background: rgba(255, 255, 138, 0.1) !important;
  }

  .mat-expansion-panel-header:hover {
    background: rgba(255, 255, 138, 0.1) !important;
  }

  .mat-expansion-panel-body{
    background: rgba(255, 255, 138, 0.1) !important;
  }
}

.mat-expansion-contenuti-custom{
  .mat-expansion-panel-header.mat-expanded {
    background: rgba(255, 255, 138, 0.1) !important;
  }

  .mat-expansion-panel-header:hover {
    background: rgba(255, 255, 138, 0.1) !important;
  }

  .mat-expansion-panel-body{
    background: rgba(255, 255, 138, 0.1) !important;
  }


}
