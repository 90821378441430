@import 'theme/theme';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');
@import '~@angular/material/theming';
@import "../node_modules/angular-calendar/css/angular-calendar.css";

html {
  min-height: 100% !important;
  display: flex !important;
}
body {
  min-height: 100% !important;
  height: 100%;
  flex: 1 !important;
  margin: 0;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
}


