@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

@mixin generateColorVariables($theme) {
  // Ottengo la lista delle palette definite nel tema
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent); // Use mat-color to extract individual colors from a palette as necessary.

  :root {
    --color-default: #66bc8f; // utilizzato quando manca la key color
    --color-primary: #{mat.get-color-from-palette($primary, 500)};
    --color-primary-200: #{mat.get-color-from-palette($primary, 200)};
    --color-primary-contrast: #{map-get(map-get($primary, contrast), 500)};
    --color-primary-300: #{mat.get-color-from-palette($primary, 300)};
    --color-primary-600: #{mat.get-color-from-palette($primary, 600)};
    --color-accent: #{mat.get-color-from-palette($accent, 500)};
    --color-accent-contrast: #{map-get(map-get($accent, contrast), 500)};
    --color-accent-300: #{mat.get-color-from-palette($accent, 300)};
    --color-accent-400: #{mat.get-color-from-palette($accent, 400)};
    --color-white: #ffffff;
    --color-black: #000000;
    --color-gray: #757575 ;
    --color-gray-blue-300: #7c92a7;
    --color-gray-blue-400: #676887;
    --color-gray-blue-500: #8b89a2;
    --color-dark-blue: #012e57;
    --color-cyan: #68c9ed;
    --color-green: #138f3a;
    --color-light-green: #66bc8f;
    --color-light-blue: #0e52c1;
    --color-light-blue-200: #e52c1;
    --color-light-blue-300: #4dc6ef;
    --color-light-blue-400: #16b5ea;
    --color-a: #1266f1;
    --color-orange: #ea8024;
    --color-amber: #fccb2f;
    --color-yellow: #fae957;
    --color-yellow-tint: #fcf089;
    --color-yellow-shade: #f9e346;
    --color-pink: #f3d6d7;
    --color-purple: #d11275;
    --color-red: #FF0000FF;
    --color-dark-sea-green: #8FBC8F;
    --color-strong-red: #b01b20;
    --color-white-0_6-transparent: rgba(255,255,255,0.6);
    --color-gray-blue-170: #9495ab;
    --color-transparent: rgba(255,255,255,0);
    --color-hover: rgba(0, 0, 0, 0.04);
    --color-gray-hr: #e2e2e2;
    --color-text-grey: #4f4f4f;
    --color-bg-footer: grey;
    --color-border-grey: #e0e0e0;
    --color-danger: #f80c35;
    --color-error: #f44336;
    --color-bg-mat-tree: rgba(38,38,38,.05);
    --color-lightgrey: lightgrey;
    --color-darkgrey: darkgrey;
    --color-border-tab: #dee2e6;
    --color-bg-calendar: #d4d4dc
  }

  /*
    COLOR MANAGEMENT
   */
  .mat-primary {
    color: #{mat.get-color-from-palette($primary, 500)} !important;
  }
  .mat-primary-200 {
    color: #{mat.get-color-from-palette($primary, 200)} !important;
  }
  .mat-primary-contrast {
    color: #{map-get(map-get($primary, contrast), 500)} !important;
  }
  .mat-primary-200 {
    color: #{mat.get-color-from-palette($primary, 200)} !important;
  }
  .mat-primary-200 {
    color: #{mat.get-color-from-palette($primary, 300)} !important;
  }
  .mat-accent {
    color: #{mat.get-color-from-palette($accent, 500)}  !important;
  }
  .mat-accent-contrast {
    color: #{map-get(map-get($accent, contrast), 500)}  !important;
  }
  .mat-white {
    color: var(--color-white) !important;
  }
  .mat-black {
    color: var(--color-black) !important;
  }
  .mat-gray {
    color: var(--color-gray)  !important;
  }
  .mat-gray-blue-300 {
    color: var(--color-gray-blue-300)  !important;
  }
  .mat-gray-blue-400 {
    color: var(--color-gray-blue-400)  !important;
  }
  .mat-cyan {
    color: var(--color-cyan)  !important;
  }
  .mat-green {
    color: var(--color-green) !important;
  }
  .mat-light-green {
    color: var(--color-light-green) !important;
  }
  .mat-light-blue {
    color: var(--color-light-blue)  !important;
  }
  .mat-light-orange {
    color: var(--color-light-orange)  !important;
  }
  .mat-light-amber {
    color: var(--color-light-amber) !important;
  }
  .mat-orange {
    color: var(--color-orange)  !important;
  }
  .mat-yellow {
    color: var(--color-yellow)  !important;
  }
  .mat-yellow-tint {
    color: var(--color-yellow-tint) !important;
  }
  .mat-yellow-shade {
    color: var(--color-yellow-shade)  !important;
  }
  .mat-pink {
    color: var(--color-pink)  !important;
  }
  .mat-purple {
    color: var(--color-purple)  !important;
  }
  .mat-red {
    color: var(--color-red)  !important;
  }

  /*
      BACKGROUND MANAGEMENT
   */
  .mat-bg-primary {
    background-color: #{mat.get-color-from-palette($primary, 500)}  !important;
  }
  .mat-bg-primary-contrast {
    background-color: #{map-get(map-get($primary, contrast), 500)}  !important;
  }
  .mat-bg-accent {
    background-color: #{mat.get-color-from-palette($accent, 500)}   !important;
  }
  .mat-bg-accent-contrast {
    background-color: #{map-get(map-get($accent, contrast), 500)}   !important;
  }
  .mat-bg-white {
    background-color: var(--color-white)  !important;
  }
  .mat-bg-black {
    background-color: var(--color-black)  !important;
  }
  .mat-bg-gray {
    background-color: var(--color-gray)   !important;
  }
  .mat-bg-cyan {
    background-color: var(--color-cyan)   !important;
  }
  .mat-bg-green {
    background-color: var(--color-green)  !important;
  }
  .mat-bg-light-green {
    background-color: var(--color-light-green)  !important;
  }
  .mat-bg-light-blue {
    background-color: var(--color-light-blue)   !important;
  }
  .mat-bg-orange {
    background-color: var(--color-orange) !important;
  }
  .mat-bg-light-orange {
    background-color: var(--color-light-orange) !important;
  }
  .mat-bg-light-amber {
    background-color: var(--color-light-amber)  !important;
  }
  .mat-bg-yellow {
    background-color: var(--color-yellow)       !important;
  }
  .mat-bg-yellow-tint {
    background-color: var(--color-yellow-tint)   !important;
  }
  .mat-bg-yellow-shade {
    background-color: var(--color-yellow-shade)   !important;
  }
  .mat-bg-pink {
    background-color: var(--color-pink) !important;
  }
  .mat-bg-purple {
    background-color: var(--color-purple) !important;
  }
  .mat-bg-red {
    background-color: var(--color-red) !important;
  }

  .mat-bg-red {
    background-color: var(--color-strong-red) !important;
  }
  .mat-bg-transparent {
    background-color: transparent !important;
  }
  .mat-bg-white-0_6-transparent {
    background-color: var(--color-white-0_6-transparent) !important;
  }
  .mat-bg-primary-200 {
    background-color: var(--color-primary-200) !important;
  }
  .mat-bg-gray-blue-170 {
    background-color: var(--color-gray-blue-170) !important;
  }
  /*
     BORDER-COLOR MANAGEMENT
  */
  .mat-border-green {
    border-color: var(--color-green)   !important;
  }
  .mat-border-cyan {
    border-color: var(--color-cyan)  !important;
  }
  .mat-border-yellow {
    border-color: var(--color-yellow)  !important;
  }
  .mat-border-light-blue {
    border-color: var(--color-light-blue) !important;
  }
  .mat-border-dark-sea-green {
    border-color: var(--color-dark-sea-green) !important;
  }
  .mat-border-transparent {
    border-color: transparent !important;
  }
  .mat-bg-strong-red {
    background-color: var(--color-strong-red) !important;
  }
  .mat-border-primary {
    border-color: #{mat.get-color-from-palette($primary, 500)} !important;
  }
  .mat-border-white {
    border-color: var(--color-white) !important;
  }
}
