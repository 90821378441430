@import '../functions';

@mixin generate-margins-and-paddings($prefix, $property) {

  // Lista delle dimensioni da generare
  $sizes: [0, .25, .5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8];

  // Spacing da utilizzare come moltiplicatore
  $spacing: 1rem;

  // Itero sulle size
  @for $i from 1 through length($sizes) {
    // Ottengo la size a partire dall'index
    $size: nth($sizes, $i);

    // Creo la regola CSS
    .#{$prefix}-#{str-replace($size, '.', '_')} {
      #{$property}: $spacing * $size !important;
    }
  }
}

@mixin generate-font-size-in-pixel() {
  // Lista delle dimensioni da generare
  $sizes: [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30];

  // Itero sulle size
  @for $i from 1 through length($sizes) {
    // Ottengo la size a partire dall'index
    $size: nth($sizes, $i);

    // Creo la regola CSS
    .fs-px-#{str-replace($size, '.', '_')} {
      font-size: $size * 1px !important;
    }
  }
}

@mixin generate-font-size-in-percentage() {
  // Lista delle dimensioni da generare
  $sizes: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500];

  // Itero sulle size
  @for $i from 1 through length($sizes) {
    // Ottengo la size a partire dall'index
    $size: nth($sizes, $i);

    // Creo la regola CSS
    .fs-pc-#{str-replace($size, '.', '_')} {
      font-size: $size + unquote("%") !important;
    }
  }
}

